import clsx from 'clsx'
import { useState } from 'react'
import styles from './NavMenuPopover.module.css'
import sharedStyles from './shared.module.css'
import SocialLinks from './SocialLinks'

export default function NavMenuPopover({ open, userSettings }) {
  const [activeNav, setActiveNav] = useState({
    community: true,
    news: true,
    editorial: true,
  })

  const handleToggle = (key) => {
    setActiveNav((prev) => ({ ...prev, [key]: !prev[key] }))
  }
  return (
    <div
      className={clsx(styles.navMenuPopover, {
        [styles.navMenuPopoverOpen]: open,
      })}
    >
      <div className={styles.navMenuDropdown}>
        <div className={styles.navMenuSection}>
          <div
            className={clsx(styles.accordionItem, {
              [styles.accordionItemOpen]: activeNav['community'],
            })}
          >
            <a className={styles.accordionItemTitle} onClick={() => handleToggle('community')}>
              <span>Community</span>
              <span className={clsx(styles.accordionIcon, sharedStyles.chevronBlackIcon)}></span>
            </a>
            <div className={styles.accordionContent}>
              <ul className={styles.accordionLinks}>
                <li>
                  <a href="/search/all">Search People</a>
                </li>
                <li>
                  <a href="/agencies/">Agency Directory</a>
                </li>
                <li>
                  <a href="/db/advertising">Discover Work</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles.accordionItem, {
              [styles.accordionItemOpen]: activeNav['news'],
            })}
          >
            <a className={styles.accordionItemTitle} onClick={() => handleToggle('news')}>
              <span>News</span>
              <span className={clsx(styles.accordionIcon, sharedStyles.chevronBlackIcon)}></span>
            </a>
            <div className={styles.accordionContent}>
              <ul className={styles.accordionLinks}>
                <li>
                  <a href="/db/trending/">Trending</a>
                </li>
                <li>
                  <a href="/rankings/">Rankings</a>
                </li>
                <li>
                  <a href="/news/">News</a>
                </li>
                <li>
                  <a href="/interviews/">Interviews</a>
                </li>
                <li>
                  <a href="/newfaces/">New Faces</a>
                </li>
                <li>
                  <a href="/lists/featured">Featured Lists</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles.accordionItem, {
              [styles.accordionItemOpen]: activeNav['editorial'],
            })}
          >
            <a className={styles.accordionItemTitle} onClick={() => handleToggle('editorial')}>
              <span>Editorial</span>
              <span className={clsx(styles.accordionIcon, sharedStyles.chevronBlackIcon)}></span>
            </a>
            <div className={styles.accordionContent}>
              <ul className={styles.accordionLinks}>
                <li>
                  <a href="/models">Models</a>
                </li>
                <li>
                  <a href="/creatives">Creatives</a>
                </li>
                <li>
                  <a href="/fashion">Fashion</a>
                </li>
                <li>
                  <a href="/beauty">Beauty</a>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.buttonGroup}>
            {userSettings?.authenticated ? (
              <a
                xmlns="http://www.w3.org/1999/xhtml"
                type="button"
                className={clsx(sharedStyles.button, sharedStyles.buttonPrimary)}
                href="/account/"
              >
                My Account
              </a>
            ) : (
              <>
                <a
                  xmlns="http://www.w3.org/1999/xhtml"
                  type="button"
                  className={clsx(sharedStyles.button, sharedStyles.buttonPrimary)}
                  href="/register/"
                >
                  Join
                </a>
                <a
                  xmlns="http://www.w3.org/1999/xhtml"
                  type="button"
                  className={clsx(sharedStyles.button, sharedStyles.buttonSecondary)}
                  href="/account/"
                >
                  Login
                </a>
              </>
            )}
          </div>
        </div>
        <div className={clsx(styles.navMenuSection, styles.navMenuFooter)}>
          <SocialLinks />

          <div className={styles.footerLinks}>
            <a href="/help/">Help &amp; FAQ</a>
            <a href="/feedback/feedback.html">Contact Us</a>
            <a href="/about/services/">About Models.com</a>
            <a href="/company/masthead.html">Masthead</a>
            <a href="/company/privacy.html">Legal / Privacy</a>
          </div>
        </div>
      </div>
    </div>
  )
}
