import styles from './LoginButton.module.css'

export default function LoginButton({ children, label = 'Log In', open, onToggle }) {
  const handleToggle = () => {
    onToggle()
  }
  return (
    <div>
      <button
        className={styles.loginBtn}
        onClick={handleToggle}
      >
        {label}
      </button>
      {children}
    </div>
  )
}
