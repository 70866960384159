export const PLAYLIST_TITLE_MIN = 3
export const PLAYLIST_TITLE_MAX = 100
export const PLAYLIST_DEFAULT_MAX_LISTS = 3
export const PLAYLIST_PRO_MAX_LISTS = 1000
export const PLAYLIST_DEFAULT_MAX_PEOPLE = 20
export const PLAYLIST_PRO_MAX_PEOPLE = 200
export const PLAYLIST_DEFAULT_MAX_SHARES = 3
export const PLAYLIST_PRO_MAX_SHARES = 25
export const VITE_PROSEARCH_VERSION = "v4.4.6"; // 'not set'
export const BIO_TEXT_MAX_LENGTH = 3048

export const PERSON_TYPES = [
  {
    personTypeNamePlural: 'Models',
    postSlug: 'model',
    name: 'Model',
  },
  {
    personTypeNamePlural: 'Photographers',
    postSlug: 'photographer',
    name: 'Photographer',
  },
  {
    personTypeNamePlural: 'Fashion Editors/Stylists',
    postSlug: 'fashion-editor-stylist',
    name: 'Fashion Editor/Stylist',
  },
  {
    personTypeNamePlural: 'Makeup Artists',
    postSlug: 'makeup-artist',
    name: 'Makeup Artist',
  },
  {
    personTypeNamePlural: 'Hair Stylists',
    postSlug: 'hair-stylist',
    name: 'Hair Stylist',
  },
  {
    personTypeNamePlural: 'Actors',
    postSlug: 'actor',
    name: 'Actor',
  },
  {
    personTypeNamePlural: 'Entertainers',
    postSlug: 'entertainer',
    name: 'Entertainer',
  },
  {
    personTypeNamePlural: 'Designers',
    postSlug: 'designer',
    name: 'Designer',
  },
  {
    personTypeNamePlural: 'Set Designers',
    postSlug: 'set-designer',
    name: 'Set Designer',
  },
  {
    personTypeNamePlural: 'Manicurists',
    postSlug: 'manicurist',
    name: 'Manicurist',
  },
  {
    personTypeNamePlural: 'Casting Directors',
    postSlug: 'casting-director',
    name: 'Casting Director',
  },
  {
    personTypeNamePlural: 'Directors',
    postSlug: 'director',
    name: 'Director',
  },
  {
    personTypeNamePlural: 'Creative Directors',
    postSlug: 'creative-director',
    name: 'Creative Director',
  },
  {
    personTypeNamePlural: 'Art Directors',
    postSlug: 'art-director',
    name: 'Art Director',
  },
  {
    personTypeNamePlural: 'Athletes',
    postSlug: 'athlete',
    name: 'Athlete',
  },
  {
    personTypeNamePlural: 'Producers',
    postSlug: 'producer',
    name: 'Producer',
  },
  {
    personTypeNamePlural: 'Groomers',
    postSlug: 'groomer',
    name: 'Groomer',
  },
  {
    personTypeNamePlural: 'Artists',
    postSlug: 'artist',
    name: 'Artist',
  },
  {
    personTypeNamePlural: 'Influencers',
    postSlug: 'influencer',
    name: 'Influencer',
  },
  {
    personTypeNamePlural: 'Editors-in-Chief',
    postSlug: 'editor-in-chief',
    name: 'Editor-in-Chief',
  },
  {
    personTypeNamePlural: 'Editors',
    postSlug: 'editor',
    name: 'Editor',
  },
  {
    personTypeNamePlural: 'Directors of Photography',
    postSlug: 'dp',
    name: 'Director of Photography',
  },
  {
    personTypeNamePlural: 'Dancers',
    postSlug: 'dancer',
    name: 'Dancer',
  },
  {
    personTypeNamePlural: 'Writers',
    postSlug: 'writer',
    name: 'Writer',
  },
  {
    personTypeNamePlural: 'Retouchers',
    postSlug: 'retoucher',
    name: 'Retoucher',
  },
  {
    personTypeNamePlural: 'Prop Stylists',
    postSlug: 'prop-stylist',
    name: 'Prop Stylist',
  },
  {
    personTypeNamePlural: 'Tailors',
    postSlug: 'tailor',
    name: 'Tailor',
  },
  {
    personTypeNamePlural: 'Fashion Directors',
    postSlug: 'fashion-director',
    name: 'Fashion Director',
  },
  {
    personTypeNamePlural: 'Choreographers',
    postSlug: 'choreographer',
    name: 'Choreographer',
  },
  {
    personTypeNamePlural: 'Executive Producers',
    postSlug: 'executive-producer',
    name: 'Executive Producer',
  },
  {
    personTypeNamePlural: 'Movement Directors',
    postSlug: 'movement-directors',
    name: 'Movement Director',
  },
  {
    personTypeNamePlural: 'Hair Colorists',
    postSlug: 'hair-colorist',
    name: 'Hair Colorist',
  },
  {
    personTypeNamePlural: 'Videographers',
    postSlug: 'videographer',
    name: 'Videographer',
  },
  {
    personTypeNamePlural: 'Video Editors',
    postSlug: 'video-editor',
    name: 'Video Editor',
  },
  {
    personTypeNamePlural: 'Sound Designers',
    postSlug: 'sounddesigner',
    name: 'Sound Designer',
  },
  {
    personTypeNamePlural: 'Heads of Editorial Content',
    postSlug: 'head-of-editorial-content',
    name: 'Head of Editorial Content',
  },
  {
    personTypeNamePlural: 'Beauty Editors',
    postSlug: 'beauty-editor',
    name: 'Beauty Editor',
  },
  {
    personTypeNamePlural: 'Journalists',
    postSlug: 'journalist',
    name: 'Journalist',
  },
  {
    personTypeNamePlural: 'Creative Consultants',
    postSlug: 'creative-consultant',
    name: 'Creative Consultant',
  },
  {
    personTypeNamePlural: 'Composers',
    postSlug: 'composer',
    name: 'Composer',
  },
  {
    personTypeNamePlural: 'Production Designers',
    postSlug: 'production-designer',
    name: 'Production Designer',
  },
  {
    personTypeNamePlural: 'Sittings Editors',
    postSlug: 'sittings-editor',
    name: 'Sittings Editor',
  },
  {
    personTypeNamePlural: 'Publicists',
    postSlug: 'publicist',
    name: 'Publicist',
  },
  {
    personTypeNamePlural: 'Illustrators',
    postSlug: 'illustrator',
    name: 'Illustrator',
  },
  {
    personTypeNamePlural: 'Artistic Directors',
    postSlug: 'artistic-director',
    name: 'Artistic Director',
  },
  {
    personTypeNamePlural: 'Interviewers',
    postSlug: 'Interviewer',
    name: 'Interviewer',
  },
  {
    personTypeNamePlural: 'Photo Editors',
    postSlug: 'photo-editor',
    name: 'Photo Editor',
  },
  {
    personTypeNamePlural: 'Fashion Market Editors',
    postSlug: 'fashion-market-editor',
    name: 'Fashion Market Editor',
  },
  {
    personTypeNamePlural: 'Milliners',
    postSlug: 'milliner',
    name: 'Milliner',
  },
  {
    personTypeNamePlural: 'Camera Operators',
    postSlug: 'camera-operator',
    name: 'Camera Operator',
  },
]

// Regular Expression Pattern (Ref: https://bit.ly/33cv2vn)
// eslint-disable-next-line
export const EMAIL_REGEX_PATTERN =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]$)/gim
export const PHONE_REGEX_PATTERN = /^\+?[0-9.() -]{7,32}$/
