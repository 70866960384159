import { useContext } from 'react'
import { AuthContext } from '../../providers/AuthContextProvider'
import PopoverMenu, { Separator } from './PopoverMenu'
import styles from './PopoverMenu.module.css'

export default function NewsNavMenu({ children }) {
  const { userSettings } = useContext(AuthContext)

  return (
    <PopoverMenu toggle={children}>
      <a className={styles.listMenuItem} href="/db/trending/">
        Trending
      </a>

      <a className={styles.listMenuItem} href="/news/">
        News
      </a>

      <a className={styles.listMenuItem} href="/interviews/">
        Interviews
      </a>

      <a className={styles.listMenuItem} href="/newfaces/">
        New Faces
      </a>

      <Separator />

      <a className={styles.listMenuItem} href="/db/favoritepeople">
        Favorite People
      </a>

      {userSettings.agencyAssociations && (
        <a className={styles.listMenuItem} href="/db/representedPeople">
          People I Represent
        </a>
      )}

      <a className={styles.listMenuItem} href="/db/magazine-cover">
        Magazine Covers
      </a>

      <a className={styles.listMenuItem} href="/db/advertising">
        Advertising
      </a>

      <a className={styles.listMenuItem} href="/db/editorial">
        Editorial
      </a>

      <a className={styles.listMenuItem} href="/db/shows">
        Shows
      </a>

      <a className={styles.listMenuItem} href="/db/socialmedia">
        Social Media
      </a>

      <a className={styles.listMenuItem} href="/db/lookbook">
        Lookbooks/Catalogs
      </a>

      <a className={styles.listMenuItem} href="/db/polaroids">
        Polaroids
      </a>
    </PopoverMenu>
  )
}
